import { NgModule } from '@angular/core';
import { ProductListComponent } from './product-list.component';
import { ProductModule } from './product.module';

import { LoaderModule } from '../loader/loader.module';
import { LoaderComponent } from '../loader/loader.component';

@NgModule({
 imports: [LoaderModule, ProductModule],
 bootstrap: [LoaderComponent, ProductListComponent]
})

export class ProductListBootstrapModule { }
