import {AfterViewInit, Component, enableProdMode} from "@angular/core";
import {HttpClientService} from "../shared/service/impl/http-client.service";
import {KitDesignLiteServiceImpl} from "../core/service/impl/KitDesignLiteServiceImpl";
import {ColourTypeEnum} from "../core/models/app-enums";
import {GoogleTagManagerServiceImpl} from "../shared/service/impl/GoogleTagManagerServiceImpl";
import {KukriProduct} from "../shopadmin/models/kukri-product";
import * as events from "events";

//declare let $:JQueryStatic;
declare var $: any;

enableProdMode();

@Component({
    selector: "my-app",
    templateUrl: "./product-list.component.html",
    styles: [
        `
            .canvas__product--dynamic-logo {
                left: 0;
                top: 0;
                z-index: 4;
                position: absolute;
                width: 100%;
                height: 100%;
            }
        `,
    ],
})
export class ProductListComponent implements AfterViewInit {
    public customer: any;
    public shop: any;
    public baseShopUrl: string;
    public bannerUrl: string;
    public shopTitle: string;
    public site: any;
    public isShopKukriUK: boolean;
    public isKukriAdmin: boolean;
    public isShopManager: boolean

    public productSearchResult: any;
    public productSearch: any;

    public products: any;
    public product: KukriProduct;
    public resultsTotalCount: any;
    public resultsRetrievedCount: any;
    public showMoreOffset: number;

    public errors: Array<String>;
    public infoMessages: Array<String>;

    public view3active: string;
    public view4active: string;
    public displayDesignMessageFlag: boolean;

    public gridSwitch: string = "col-6 col-md-4";

    // Colours Dropdown
    public colourTypeEnum = ColourTypeEnum;
    public selectedMainColour: any;
    public selectedSecondaryColour: any;
    public selectedDetailColour: any;
    public productColours: any;
    public secondaryColours: any;

    public primaryLogoURL: string;
    public kdHidePricesFlag: boolean;

    public designUKAdmin: boolean;

    public productGroups: any;


    constructor(private httpClientService: HttpClientService, private kitDesignLiteService: KitDesignLiteServiceImpl,
                private googleTagManagerService: GoogleTagManagerServiceImpl) {
        let jsonData = $("#pageInputJson").val();
        jsonData = $.parseJSON(jsonData);

        this.customer = jsonData.customer;
        this.shop = jsonData.shop;
        this.site = jsonData.site;
        this.shopTitle = jsonData.shopTitle;

        this.isKukriAdmin = jsonData.isKukriAdmin;
        this.isShopManager = jsonData.isShopManager;

        this.view3active = "active";
        this.view4active = "";

        this.baseShopUrl = jsonData.baseShopUrl;

        if (jsonData.productColours != undefined && jsonData.productColours != null) {
            this.productColours = jsonData.productColours;
        }

        if (jsonData.secondaryColours != undefined && jsonData.secondaryColours != null) {
            this.secondaryColours = jsonData.secondaryColours;
        }

        if (jsonData.primaryLogo) {
            this.primaryLogoURL = jsonData.primaryLogo.logoUrl;
        }

        if (jsonData.kdHidePricesFlag) {
            this.kdHidePricesFlag = jsonData.kdHidePricesFlag;
        } else {
            this.kdHidePricesFlag = false;
        }

        this.bannerUrl = jsonData.bannerUrl;

        this.productSearch = jsonData.productSearch;
        this.products = jsonData.products;
        this.productGroups = {};

        this.resultsRetrievedCount = jsonData.resultsRetrievedCount;
        this.resultsTotalCount = jsonData.resultsTotalCount;

        this.showMoreOffset = 0;

        this.productSearch.resultsDisplayLimit = this.productSearch.loadMoreStepLimit;

        if (jsonData.displayDesignMessageFlag != undefined) {
            this.displayDesignMessageFlag = jsonData.displayDesignMessageFlag;
        }

        // sort products based on sort order
        // NOTE: sort must happen before the filter - keep these two lines in this order
        this.sortProductsBySortOrder(this.products);
        this.filterProductsFrontEnd();

        let percentageOfCanvasSize = 0.6055555556;
        let pixelRatio = 0.183486239;

        for (let prod of this.products) {
            if (
                prod.productAttributes != null &&
                prod.productType == "CustomisedStock" &&
                prod.dynamicNanImageFlag == true &&
                this.imageOverride(prod.imageFront) == false
            ) {
                for (let attr of prod.productAttributes) {
                    if (
                        attr.attributeType == "LOGO-CS" &&
                        attr.positionView == "front" &&
                        attr.positionX > 0
                    ) {
                        // Only display the first logo in a position, too much logic to get offset correct otherwise
                        if (attr.positionSequence != null && attr.positionSequence > 1) {
                            continue;
                        }
                        let positionXasPixels = attr.positionX * percentageOfCanvasSize;
                        attr.positionXasPercentage = positionXasPixels * pixelRatio + "%";
                        let positionYasPixels = attr.positionY * percentageOfCanvasSize;

                        if (this.shop.logoImageOffsetY != null) {
                            positionYasPixels = (attr.positionY + this.shop.logoImageOffsetY) * percentageOfCanvasSize;
                        }

                        attr.positionYasPercentage = positionYasPixels * pixelRatio + "%";

                        if (this.shop.dynamicShopLogosOnlyFlag == true && attr.dynamicLogoFlag == true) {
                            attr.logoUrl = this.primaryLogoURL;
                        }
                    }
                }
            }
        }

        this.isShopKukriUK = false;
        if (this.site.systemCountry.countryCode == "UK" && this.shop.url == "kukri") {
            if (this.productSearch.orderType == "Design" && this.isKukriAdmin) {
                this.designUKAdmin = true;
            } else {
                this.designUKAdmin = false;
            }

            this.isShopKukriUK = true;
        }

        this.setActiveView(3);
    }

    // sort products based on sort order
    public sortProductsBySortOrder(productList: any[]) {
        this.products =
            productList.sort((a, b) => (a.sortOrder > b.sortOrder) ? 1 : ((b.sortOrder > a.sortOrder) ? -1 : 0));
    }

    public ngAfterViewInit() {
        // MJ Moved from build.js, used for banner sliders
        // Auto play now set to true to auto slide
        $(".kukri-slider").slick({
            arrows: false,
            dots: false,
            autoplay: true,
            autoplaySpeed: 4000,
            speed: 400,
        });

        let productFilters = (function($) {
            let s: any;

            return {
                settings: {
                    filterTitle: ".filter__title",
                    filterContent: ".filter__content",
                    filterMoreLink: ".filter--more-link",
                    viewThree: ".product-filtered-grid__view--three",
                    viewFour: ".product-filtered-grid__view--four",
                    $mobileFilter: $(".mobile-filters-tab__filter"),
                    $mobileSort: $(".mobile-filters-tab__sort"),
                    $mobileFilterContent: $(".mobile-filters-content__filter"),
                    $mobileSortContent: $(".mobile-filters-content__sort"),
                },

                init: function() {
                    s = this.settings;
                    s.$mobileFilter.on("click", function(e: any) {
                        $(this).toggleClass("active");
                        s.$mobileFilterContent.toggleClass("active");
                        s.$mobileSort.removeClass("active");
                        s.$mobileSortContent.removeClass("active");
                        e.preventDefault();
                    });
                    s.$mobileSort.on("click", function(e: any) {
                        $(this).toggleClass("active");
                        s.$mobileSortContent.toggleClass("active");
                        s.$mobileFilter.removeClass("active");
                        s.$mobileFilterContent.removeClass("active");
                        e.preventDefault();
                    });

                    // toggle filters
                    $(document).on("click", s.filterTitle, function(e) {
                        $(this).toggleClass("active");
                        $(this).next(".filter__content").toggleClass("active");
                        e.preventDefault();
                    });

                    // toggle View more
                    $(document).on("click", s.filterMoreLink, function(e :any) {
                        $(this).toggleClass("active");
                        $(this).prev(".filter--more-container").toggleClass("active");
                        e.preventDefault();
                    });
                },

                // function for setting proper tab height
                wrapResize: function() {
                    var personaliseHeight = s.$tab_personalise.outerHeight();
                    var tablinksHeight = s.$tabLinks_wrap.outerHeight();
                    s.$tabs_wrap.css("height", personaliseHeight + tablinksHeight);
                },
            };
        })(jQuery);
        productFilters.init();

        window.scrollTo(window.scrollX, window.scrollY - 1);
        window.scrollTo(window.scrollX, window.scrollY + 1);
        $(function() {
            $('[data-toggle="tooltip"]').tooltip();
        });
    }

    public filterBackEnd(lastFilterCategoryCode: string) {
        this.productSearch.lastFilterCategoryCode = lastFilterCategoryCode;

        let uri: string = "productNavListAjaxNg.action";
        let uriPostNonAjax: string = "shopProductsNg.action";
        // Build Post body
        let submitData = { productSearch: this.productSearch };

        try {
            this.httpClientService.postAjaxForm(uri, submitData).subscribe(
                response => {
                    if (response != null) {
                        if (response["productSearch"] != null && response["productSearch"] != undefined) {
                            this.productSearch = response["productSearch"];
                            this.products = response["products"];

                            if (this.productSearch.orderType == "Design") {
                                this.productColours = response["productColours"];
                                this.secondaryColours = response["secondaryColours"];
                            }
                            if (this.products != null) {
                                if (this.selectedMainColour != undefined && this.selectedMainColour != null &&
                                    this.selectedMainColour.hexValue != undefined &&
                                    this.selectedMainColour.hexValue != null &&
                                    this.selectedMainColour.hexValue != "") {
                                    this.changeColourEvent(this.selectedMainColour.hexValue, this.colourTypeEnum.MainColour);
                                }

                                if (this.selectedSecondaryColour != undefined && this.selectedSecondaryColour != null &&
                                    this.selectedSecondaryColour.hexValue != undefined &&
                                    this.selectedSecondaryColour.hexValue != null &&
                                    this.selectedSecondaryColour.hexValue != "") {
                                    this.changeColourEvent(this.selectedSecondaryColour.hexValue, this.colourTypeEnum.SecondaryColour);
                                }

                                if (this.selectedDetailColour != undefined && this.selectedDetailColour != null &&
                                    this.selectedDetailColour.hexValue != undefined &&
                                    this.selectedDetailColour.hexValue != null &&
                                    this.selectedDetailColour.hexValue != "") {
                                    this.changeColourEvent(this.selectedDetailColour.hexValue, this.colourTypeEnum.DetailColour);
                                }

                                let percentageOfCanvasSize = 0.6055555556;
                                let pixelRatio = 0.183486239;

                                for (let prod of this.products) {
                                    if (prod.productAttributes != null && prod.productType == "CustomisedStock" &&
                                        prod.dynamicNanImageFlag == true &&
                                        this.imageOverride(prod.imageFront) == false) {
                                        for (let attr of prod.productAttributes) {
                                            if (attr.attributeType == "LOGO-CS" && attr.positionView == "front" &&
                                                attr.positionX > 0) {
                                                // Only display the first logo in a position, too much logic to get offset correct otherwise
                                                if (attr.positionSequence != null && attr.positionSequence > 1) {
                                                    continue;
                                                }
                                                let positionXasPixels = attr.positionX * percentageOfCanvasSize;
                                                attr.positionXasPercentage = positionXasPixels * pixelRatio + "%";
                                                let positionYasPixels = attr.positionY * percentageOfCanvasSize;

                                                if (this.shop.logoImageOffsetY != null) {
                                                    positionYasPixels = (attr.positionY + this.shop.logoImageOffsetY) * percentageOfCanvasSize;
                                                }

                                                attr.positionYasPercentage = positionYasPixels * pixelRatio + "%";

                                                if (this.shop.dynamicShopLogosOnlyFlag == true && attr.dynamicLogoFlag == true) {
                                                    attr.logoUrl = this.primaryLogoURL;
                                                }
                                            }
                                        }
                                    }
                                }
                            }

                            this.resultsRetrievedCount = response["resultsRetrievedCount"];
                            this.resultsTotalCount = response["resultsTotalCount"];

                            if (lastFilterCategoryCode === 'productSearch.sortBy') {
                                $('.mobile-filters-tab__sort').click()
                            } else {
                                $('.mobile-filters-tab__filter').click()
                            }

                            // Could reset showMore limit, but leaving as is for now
                            this.filterProductsFrontEnd();
                        }

                        if (response["errors"] != null && response["errors"] != undefined) {
                            this.errors = response["errors"];
                            return;
                        }

                        if (response["infoMessages"] != undefined && response["infoMessages"] != null) {
                            this.infoMessages = response["infoMessages"];
                        }
                    }
                },
                error => (window.location.href = uriPostNonAjax)
            );
        } catch (e) {
            alert(e);
        }
    }

    public removeFilterCategory(categoryCode: string) {
        //alert("alert removeFilterCategory");

        try {
            if (this.productSearch.orderType == "Design") {
                let filtersSelected = 0;
                for (let filterCat of this.productSearch
                    .sideFilterSearchCategories) {
                    if (filterCat.selected) {
                        filtersSelected = filtersSelected + 1;
                    }
                }
                if (filtersSelected == 1) {
                    return;
                }
            }

            if (
                categoryCode ==
                this.productSearch.shopProductGroupSearchCategory.code
            ) {
                this.productSearch.shopProductGroupSearchCategory.selected = false;
                for (
                    let i = 0;
                    i <
                    this.productSearch.shopProductGroupSearchCategory
                        .searchCategoryOptions.length;
                    i++
                ) {
                    if (
                        this.productSearch.shopProductGroupSearchCategory
                            .searchCategoryOptions[i].selected == true
                    ) {
                        this.productSearch.shopProductGroupSearchCategory.searchCategoryOptions[
                            i
                        ].selected = false;
                    }
                }
            } else if (categoryCode == "price") {
                // price is only filtered at front end
                this.productSearch.priceFilter = false;
                this.productSearch.priceMinLimit = this.productSearch.priceMin;
                this.productSearch.priceMaxLimit = this.productSearch.priceMax;
                this.filterProductsFrontEnd();
            } else {
                for (
                    let i = 0;
                    i < this.productSearch.sideFilterSearchCategories.length;
                    i++
                ) {
                    if (
                        this.productSearch.sideFilterSearchCategories[i].code ==
                        categoryCode
                    ) {
                        this.productSearch.sideFilterSearchCategories[
                            i
                        ].selected = false;
                        for (
                            let j = 0;
                            j <
                            this.productSearch.sideFilterSearchCategories[i]
                                .searchCategoryOptions.length;
                            j++
                        ) {
                            if (
                                this.productSearch.sideFilterSearchCategories[i]
                                    .searchCategoryOptions[j].selected == true
                            ) {
                                this.productSearch.sideFilterSearchCategories[
                                    i
                                ].searchCategoryOptions[j].selected = false;
                            }
                        }
                    }
                }

                if (this.productSearch.mainTopMenuSearchCategoryDescription == categoryCode) {
                    this.productSearch.mainTopMenuSearchCategoryDescription = null;
                    this.productSearch.mainTopMenuSearchCategoryOptionDescription = null;
                    this.productSearch.secondTopMenuSearchCategoryDescription = null;
                    this.productSearch.secondTopMenuSearchCategoryOptionDescription = null;
                }

                if (this.productSearch.secondTopMenuSearchCategoryDescription == categoryCode) {
                    this.productSearch.secondTopMenuSearchCategoryDescription = null;
                    this.productSearch.secondTopMenuSearchCategoryOptionDescription = null;
                }
            }

        } catch (e) {
            alert("exception=" + e);
        }
        this.filterBackEnd(categoryCode);
    }

    public mapTextToHexCode(colour: string) {
        let hexcode = "";
        //alert('mapTextToHexCode called');
        if (colour == "Navy") hexcode = "#05173C";
        if (colour == "Off White") hexcode = "#FCFCCE";
        if (colour == "Bottle Green") hexcode = "#0D280B";

        return hexcode;
    }
    public applyFilterCategory(categoryName: string, categoryValue: string) {
        if (categoryName == "productGroup") {
        }
        this.filterResultsBackEnd();
    }

    public filterPrices() {
        if (this.productSearch.priceMinLimit > this.productSearch.priceMin ||
            this.productSearch.priceMaxLimit < this.productSearch.priceMax) {
            this.productSearch.priceFilter = true;
        } else {
            this.productSearch.priceFilter = false;
        }

        this.filterProductsFrontEnd();
    }

    // TODO: Review this code, ideally remove completely
    public filterProductsFrontEnd() {
        let productDatas: Array<any> = [];
        let productData: any;
        let productShowCount: number = 0;
        let productTotalCount: number = 0;
        // console.log("products in filter function: ", this.products);
        for (let i = 0; i < this.products.length; i++) {
            // TODO: If priceFilter is set, apply the price limit on the front end
            if (this.productSearch.priceFilter) {
                if (this.products[i].unitChargeInclTax >= this.productSearch.priceMinLimit &&
                    this.products[i].unitChargeInclTax <= this.productSearch.priceMaxLimit)
                {
                    productTotalCount++;
                    if (productShowCount <= this.productSearch.resultsDisplayLimit) {
                        this.products[i].show = true;
                        productShowCount++;
                    }
                } else {
                    this.products[i].show = false;
                }
                // TODO: Else, just apply the results display limit only
            } else {
                productTotalCount++;
                if (productShowCount <= this.productSearch.resultsDisplayLimit) {
                    this.products[i].show = true;
                    productShowCount++;
                } else {
                    this.products[i].show = false;
                }
            }
        }

        this.resultsTotalCount = productTotalCount;
        this.resultsRetrievedCount = productShowCount;
        // Build the product data to send to Google Analytics
        // https://developers.google.com/tag-manager/enhanced-ecommerce#product-impressions
        let productsRemaining: number = parseInt(this.resultsTotalCount) - this.productSearch.resultsDisplayLimit;

        try {
            if (productsRemaining < this.productSearch.loadMoreStepLimit) {
                for (let i = this.showMoreOffset; i < parseInt(this.resultsTotalCount); i++) {
                    productData = this.googleTagManagerService.buildImpressionDataForGTMPush(
                        this.products[i].code, // ID
                        this.products[i].description, // Name
                        `${this.shopTitle} - Main Products Listing Page`, // List hardcoded for now, need to make dynamic for filtered lists and upselling lists
                        "Kukri", // Brand
                        this.products[i].productType, // Category
                        null, // Variant
                        i, // Position
                        this.products[i].unitChargeExclTax // Price
                    );
                    productDatas.push(productData);
                }
            } else {
                for (let i = this.showMoreOffset; i <= this.productSearch.resultsDisplayLimit; i++) {
                    productData = this.googleTagManagerService.buildImpressionDataForGTMPush(
                        this.products[i].code, // ID
                        this.products[i].description, // Name
                        `${this.shopTitle} - Main Products Listing Page`,
                        "Kukri", // Brand
                        this.products[i].productType, // Category
                        null, // Variant
                        i, // Position
                        this.products[i].unitChargeExclTax // Price
                    );
                    productDatas.push(productData);
                }
            }
            this.googleTagManagerService.pushImpressionProductEventToGoogleAnalytics(
                productDatas
            );
        } catch (e) {
        }

        this.produceGroupings();
    }

    public filterSleeveType() {
        let productShowCount = 0;
        let productTotalCount = 0;

        let option1 = "xxx";
        let option2 = "xxx";
        if (
            this.productSearch.sleeveTypeSearchCategory.searchCategoryOptions[0]
                .selected
        ) {
            option1 = this.productSearch.sleeveTypeSearchCategory
                .searchCategoryOptions[0].description;
        }
        if (
            this.productSearch.sleeveTypeSearchCategory.searchCategoryOptions[1]
                .selected
        ) {
            option2 = this.productSearch.sleeveTypeSearchCategory
                .searchCategoryOptions[1].description;
        }
        for (let prod of this.products) {
            if (option1 != "xxx" || option2 != "xxx") {
                if (prod.sleeveType == option1 || prod.sleeveType == option2) {
                    productTotalCount++;
                    if (
                        productShowCount <=
                        this.productSearch.resultsDisplayLimit
                    ) {
                        prod.show = true;
                        // productsLocalFiltered[productShowCount] = this.products[i];
                        productShowCount++;
                    }
                } else {
                    prod.show = false;
                }
            } else {
                productTotalCount++;
                if (
                    productShowCount <= this.productSearch.resultsDisplayLimit
                ) {
                    prod.show = true;
                    // productsLocalFiltered[productShowCount] = this.products[i];
                    productShowCount++;
                } else {
                    prod.show = false;
                }
            }
        }

        this.resultsTotalCount = productTotalCount;
        this.resultsRetrievedCount = productShowCount;
    }

    public filterResultsBackEnd() {}

    public backendClear() {}

    public backendDisplayProduct() {}

    public backendDisplayDesign() {}

    public frontendFilter() {}

    public loadMore() {}

    public showMore() {
        if (this.showMoreOffset == 0) {
            this.showMoreOffset = this.showMoreOffset + parseInt(this.productSearch.loadMoreStepLimit) + 1;
        } else {
            this.showMoreOffset = this.showMoreOffset + parseInt(this.productSearch.loadMoreStepLimit);
        }

        this.productSearch.resultsDisplayLimit = 1+ this.productSearch.resultsDisplayLimit + this.productSearch.loadMoreStepLimit ;
        this.filterProductsFrontEnd();
    }

    public deriveProductUrl(product: any): string {
        // ZS : Build colour URL
        let colourUrl: string = "";
        if (
            this.selectedMainColour != undefined &&
            this.selectedMainColour != null &&
            this.selectedMainColour.hexValue != undefined &&
            this.selectedMainColour.hexValue != null &&
            this.selectedMainColour.hexValue != ""
        ) {
            colourUrl =
                colourUrl +
                "&selectedMainColour=" +
                this.selectedMainColour.hexValue.replace("#", "");
        }
        if (
            this.selectedSecondaryColour != undefined &&
            this.selectedSecondaryColour != null &&
            this.selectedSecondaryColour.hexValue != undefined &&
            this.selectedSecondaryColour.hexValue != null &&
            this.selectedSecondaryColour.hexValue != ""
        ) {
            colourUrl =
                colourUrl +
                "&selectedSecondaryColour=" +
                this.selectedSecondaryColour.hexValue.replace("#", "");
        }
        if (
            this.selectedDetailColour != undefined &&
            this.selectedDetailColour != null &&
            this.selectedDetailColour.hexValue != undefined &&
            this.selectedDetailColour.hexValue != null &&
            this.selectedDetailColour.hexValue != ""
        ) {
            colourUrl =
                colourUrl +
                "&selectedDetailColour=" +
                this.selectedDetailColour.hexValue.replace("#", "");
        }

        let action = null;

        if (product.productType == "Bundle") {
            action =
                "/buyBundleProductNg?shopBundleId=" + product.baseProductId;
        } else if (product.productType == "DesignTemplate") {
            action =
                "/kdSelectFit?designTemplateId=" +
                product.baseProductId +
                colourUrl;
        } else if (product.productType == "Design") {
            action =
                "/buyDesignProductNg?productId=" +
                product.baseProductId +
                colourUrl;
        } else {
            action = "/retrieveProdNg?prodId=" + product.baseProductId;
            if (product.shopProductVariationId != null) {
                action =
                    action +
                    "&shopProdVariationId=" +
                    product.shopProductVariationId;
            }
        }
        let url = "/teamshop/" + this.shop.url + action;

        return url;
    }

    public viewProduct() {
        alert("alert viewProduct");
    }

    // TODO: This function could itself be moved to the service...
    // Send Product Click information to Google Analytics
    public gtmProductClick(
        productObject: any,
        list: string,
        position: number
    ): void {
        let productDatas: Array<any> = new Array();

        let productData: any = this.googleTagManagerService.buildProductDataForGTMPush(
            productObject.code, // ID
            productObject.description, // Name
            "Kukri", // Brand
            productObject.productType, // Category
            null, // Variant
            productObject.unitChargeExclTax, // Price
            null, // Quantity
            null, // Coupon
            position // Uses Index
        );

        list = `${this.shopTitle} - ${list}`;

        if (productData != undefined && productData != null) {
            productDatas.push(productData);
        }

        this.googleTagManagerService.pushClickProductEventToGoogleAnalytics(
            productDatas,
            list
        );
    }

    public setActiveView(viewNum: number) {
        let bootstrapCols: string;

        this.hideThumbnails()

        // if (this.productSearch.orderType == 'Design' && this.site.systemCountry.countryCode == 'UK') {
        if ((this.isShopKukriUK && !this.designUKAdmin) || this.shop.url == 'runtogether' || this.shop.url == 'runtogether-leaders' || this.shop.url == 'ulsterrugby') {
            bootstrapCols = "col-lg-3";
        } else {
            bootstrapCols = "";
        }

        if (viewNum == 3) {
            this.view3active = "active";
            this.view4active = "";
            this.gridSwitch = `col-6 col-md-4 ${bootstrapCols}`;
        } else {
            this.view3active = "";
            this.view4active = "active";
            this.gridSwitch = `col-6 col-md-4 col-xl-3 ${bootstrapCols}`;
        }
    }

    public changeColourEvent(hexValue: string, selectedColourTypeEnum: ColourTypeEnum): void {
        //   console.log("Calling method: changeColourEvent");
        try {
            let kukriColour: any = null;
            if (selectedColourTypeEnum == ColourTypeEnum.MainColour) {
                kukriColour = this.kitDesignLiteService.findColourFromColourList(
                    hexValue,
                    this.productColours
                );
            } else {
                kukriColour = this.kitDesignLiteService.findColourFromColourList(
                    hexValue,
                    this.secondaryColours
                );
            }

            //let kukriColour: any = this.kitDesignLiteService.findColourFromColourList(
            //   hexValue,
            //   this.productColours
            //);

            if (kukriColour != null) {
                let cloneKukriColour = Object.create(kukriColour);
                switch (selectedColourTypeEnum) {
                    case ColourTypeEnum.MainColour:
                        this.selectedMainColour = cloneKukriColour;
                        break;
                    case ColourTypeEnum.SecondaryColour:
                        this.selectedSecondaryColour = cloneKukriColour;
                        break;
                    case ColourTypeEnum.DetailColour:
                        this.selectedDetailColour = cloneKukriColour;
                        break;
                }
                this.applyColourToProducts(hexValue, selectedColourTypeEnum);
            } else {
                switch (selectedColourTypeEnum) {
                    case ColourTypeEnum.MainColour:
                        this.selectedMainColour = null;
                        break;
                    case ColourTypeEnum.SecondaryColour:
                        this.selectedSecondaryColour = null;
                        break;
                    case ColourTypeEnum.DetailColour:
                        this.selectedDetailColour = null;
                        break;
                }
            }
        } catch (e) {
            console.log(e);
        }
        //   console.log("Completed method: changeColourEvent");
    }

    private applyColourToProducts(hexValue: string, selectedColourTypeEnum: ColourTypeEnum): void {
        if (
            this.products != undefined &&
            this.products != null &&
            this.products.length > 0
        ) {
            for (let product of this.products) {
                if (
                    product != undefined &&
                    product != null &&
                    (product.productType == "DesignTemplate" ||
                        product.productType == "Design") &&
                    product.imageFront != undefined &&
                    product.imageFront != null &&
                    product.imageFront != ""
                ) {
                    switch (selectedColourTypeEnum) {
                        case ColourTypeEnum.MainColour:
                            if (hexValue != undefined && hexValue != null) {
                                let imageFront: string = this.kitDesignLiteService.applyColourToDesign2(
                                    hexValue,
                                    this.colourTypeEnum.MainColour,
                                    product.imageFront
                                );
                                if (
                                    imageFront != undefined &&
                                    imageFront != null &&
                                    imageFront != ""
                                ) {
                                    product.imageFront = imageFront;
                                }
                            }
                            break;
                        case ColourTypeEnum.SecondaryColour:
                            if (hexValue != undefined && hexValue != null) {
                                let imageFront: string = this.kitDesignLiteService.applyColourToDesign2(
                                    hexValue,
                                    this.colourTypeEnum.SecondaryColour,
                                    product.imageFront
                                );
                                if (
                                    imageFront != undefined &&
                                    imageFront != null &&
                                    imageFront != ""
                                ) {
                                    product.imageFront = imageFront;
                                }
                            }
                            break;
                        case ColourTypeEnum.DetailColour:
                            if (hexValue != undefined && hexValue != null) {
                                let imageFront: string = this.kitDesignLiteService.applyColourToDesign2(
                                    hexValue,
                                    this.colourTypeEnum.DetailColour,
                                    product.imageFront
                                );
                                if (
                                    imageFront != undefined &&
                                    imageFront != null &&
                                    imageFront != ""
                                ) {
                                    product.imageFront = imageFront;
                                }
                            }
                            break;
                    }
                }
            }
        }
    }

    public imageOverride(imageName: string): boolean {
        let nameSplit = imageName.split("_");
        if (nameSplit.length > 2) {
            return true;
        } else {
            return false;
        }
    }

    public groupDelimeter(product: any): any {
        return [' -', ' ('].filter(chars => product.description.indexOf(chars) !== -1)
    }

    public sanitiseProductName(product: any) {
        let charList: string[] = this.groupDelimeter(product)
        let useProductName: any

        charList.filter(chars => product.description.indexOf(chars) !== -1).forEach(chars => {
            const nameSplit = product.description.split(chars)

            if (!useProductName && nameSplit.length > 1 && nameSplit[0].split(' ').length > 0 &&
                ['Mens', 'Womens', 'Youth', 'Kids', 'Girls', 'Unisex', 'Adult', 'Ladies'].indexOf(nameSplit[1].trim()) === -1) {
                useProductName = nameSplit[0]
            }
        })

        return this.products.length > 8 && charList.length !== 0 && useProductName ? useProductName : product.description
    }


    private produceGroupings() :void {
        const temp = {}

        this.products.forEach((product:any) => {

            if (product.show ) {
                let productName = this.sanitiseProductName(product)

                if (Object.keys(temp).indexOf(productName) === -1) {
                    temp[productName] = {
                        default : 0,
                        products : []
                    }
                }

                if (product.description.indexOf(productName) > -1 &&
                    temp[productName].products.filter((storedProduct: any) =>
                        storedProduct.code.localeCompare(product.code) === 0 &&
                        storedProduct.description.localeCompare(product.description) === 0).length  === 0) {
                    // if (this.shop.showWasnowPricingFlag) {
                    //     product.newTag = 'New'
                    // }
                    temp[productName].products.push(product)
                } else {
                    this.resultsRetrievedCount -= 1
                    this.resultsTotalCount -= 1
                }
            }
        })

        this.productGroups = []
        Object.keys(temp).forEach(key => this.productGroups.push(temp[key]))

        // console.log('products', this.products)
        // console.log('productGroups', this.productGroups)
        // console.log("productSearch", this.productSearch)
        // console.log("jsonData", $.parseJSON($("#pageInputJson").val()))

        this.toggleTooltip();
    }

    public hideThumbnails() :void {
        const adjustClasses =
            "full_wrap left_first left_3_second left_3_third " +
            "left_3_minus_last left_3_minus_last_but_one " +
            "left_3_minus_last_a left_3_minus_last_but_one_a " +
            "left_3_minus_last_b left_3_minus_last_but_one_b " +
            "left_3_minus_last_c left_3_minus_last_but_one_c " +
            "left_3_minus_last_d left_3_minus_last_but_one_d " +

            "left_4_second left_4_third left_4_fourth " +
            "left_4_minus_last left_4_minus_last_but_one left_4_minus_last_but_two " +
            "left_4_minus_last_a left_4_minus_last_but_one_a left_4_minus_last_but_two_a " +
            "left_4_minus_last_b left_4_minus_last_but_one_b left_4_minus_last_but_two_b " +
            "left_4_minus_last_c left_4_minus_last_but_one_c left_4_minus_last_but_two_c " +
            "left_4_minus_last_d left_4_minus_last_but_one_d left_4_minus_last_but_two_d "

        $('.shop-item__grouped_products_wrap:visible').each((i: number, grouped_products: any) => {
            $(grouped_products).removeClass(adjustClasses)
        })
    }

    public toggleThumbnails(event: any, groupIdx: any) :void {

        if (event.type === "click") {
            event.preventDefault();
            event.stopPropagation();
        }

        this.hideThumbnails()

        if (groupIdx !== null) {
            let alignLeft,
                gridView = $('.product-filtered-grid__view').length > 0,
                viewGroupings = gridView && this.view3active ? 3 : 4,
                numGroupings = this.productGroups.length,
                numGroupingsLeft = numGroupings - groupIdx -1,
                productGroupIdx = groupIdx + viewGroupings,
                in_full_row = parseInt(String(groupIdx / viewGroupings)) <
                    parseInt(String(numGroupings / viewGroupings))

            if (numGroupings % viewGroupings === 0 || numGroupingsLeft >= viewGroupings || in_full_row) {

                if (viewGroupings == 3) {
                    switch (productGroupIdx % viewGroupings) {
                        case 1:
                            alignLeft = "left_3_second"
                            break;
                        case 2:
                            alignLeft = "left_3_third"
                            break;
                        default:
                            alignLeft = "left_first"
                    }
                }

                if (viewGroupings == 4) {
                    switch (productGroupIdx % viewGroupings) {
                        case 1:
                            alignLeft = "left_4_second"
                            break;
                        case 2:
                            alignLeft = "left_4_third"
                            break;
                        case 3:
                            alignLeft = "left_4_fourth"
                            break;
                        default:
                            alignLeft = "left_first"
                    }
                }
            } else {
                if (viewGroupings == 3) {
                    switch (numGroupingsLeft) {
                        case 0:
                            alignLeft = "left_3_minus_last"
                            break;
                        case 1:
                            alignLeft = "left_3_minus_last_but_one"
                            break;
                        default:
                    }
                }

                if (viewGroupings == 4) {
                    switch (numGroupingsLeft) {
                        case 0:
                            alignLeft = "left_4_minus_last"
                            break;
                        case 1:
                            alignLeft = "left_4_minus_last_but_one"
                            break;
                        case 2:
                            alignLeft = "left_4_minus_last_but_two"
                            break;
                        default:
                    }
                }

                if (!in_full_row) {
                    switch (parseInt(String(numGroupings % viewGroupings))) {
                        case 1:
                            alignLeft += "_a"
                            break;
                        case 2:
                            alignLeft += "_b"
                            break;
                        case 3:
                            alignLeft += "_c"
                            break;
                        default:
                            alignLeft += "_c"
                    }
                }
            }

            if (!gridView) {
                alignLeft = " full_wrap " + alignLeft
            }

            const thumbnail = $('[id^=productThumbnail-'+groupIdx+'-0]')
            const wrap = thumbnail.parent().parent()
            wrap.addClass(alignLeft)

            if (this.productGroups[groupIdx].products.length > 4) {
                thumbnail.find('a').focus().blur()
            }

            wrap.parent().find('.shop-item__details__title:first').focus()[0]
                .scrollIntoView({ behavior: "smooth", block: "start", inline: "nearest" })

            setTimeout(() => {
                $("html, body").animate({
                    scrollTop: $(document).scrollTop() -0.5
                }, 10);
            }, 500)
        }
    }

    private toggleTooltip() {
        setTimeout(() => {
            // $('[data-toggle="tooltip"]').each(function() {
            //     $(this).on('click', function () {$(this).tooltip('hide')}).tooltip();
            // });
        }, 10)
    }
}
